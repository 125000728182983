import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { alumno_baja, insert_update_alumno_estado } from '../../../services/alumnos';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import red from '@material-ui/core/colors/red';
import Swal from 'sweetalert2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@material-ui/core/colors';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginTop: 90
//         // display: 'flex',
//         // flexWrap: 'wrap',
//     },
//     textField: {
//         // marginLeft: theme.spacing(1),
//         // marginRight: theme.spacing(1),
//     },
//     keyboardDatePicker: {
//         // marginLeft: theme.spacing(1),
//         // marginRight: theme.spacing(1),
//     },
//     formulario: {
//         margin: 8
//     },
//     input: {
//         display: 'none'
//     },
//     chipCancelado: {
//         backgroundColor: red[100]
//     }
// }));

/* -------------------------------------------------------------------------- */
/*                            Esquema de valicación                           */
/* -------------------------------------------------------------------------- */

const AlumnosStatus = ({style, alumno_id, status_id, fecha_baja}) => {
    // const classes = useStyles();
    // const {id: alumno_id} = useParams()

    const [statusId, setStatusId] = useState(status_id)
    
    // let history = useHistory();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const BotonStatus = ({original_status_id, label, estados_activan, color}) => {
        const handleClick = () => {
            Swal.fire({
                title: '¿Realmente deseas cambiar el estado del alumno?',
                showCancelButton: true,
                confirmButtonText: 'Cambiar estado',
                cancelButtonText: `Cancelar`,
                confirmButtonColor: '#afcb0d',
                cancelButtonColor: '#a5a5a5',
                icon: 'warning',
                reverseButtons: true
              }).then((result) => {

                if (result.isConfirmed) {
                    if(statusId !== original_status_id) {
                        console.log(original_status_id)
                        if(original_status_id === '0') {
                            alumno_baja( {usuario_id: usuarioSeleccionado.id, alumno_id: alumno_id}).then( (response) => {
                                window.location.reload(false);
                            })
                        } else {
                            insert_update_alumno_estado( { usuario_id: usuarioSeleccionado.id, alumno_id: alumno_id, estado_id: original_status_id }).then( (response) => {

                                setStatusId(original_status_id)
                                
                            })
                        }
                    }
                }
            })
        }

        if( original_status_id === "0" && (fecha_baja !== '0000-00-00 00:00:00' && fecha_baja != null)) {
            return <Button onClick={ () => Swal.fire('Este alumno ya esta dado de baja', '', 'info') } variant={statusId === original_status_id ? 'contained' : 'outlined'} style={{color: 'white', borderColor: color, backgroundColor: color}}>{label}</Button>
        } else {
            if(estados_activan) {

                if(estados_activan.includes(statusId) && (fecha_baja === '0000-00-00 00:00:00' || fecha_baja == null) )
                    return <Button onClick={() => handleClick()} variant={statusId === original_status_id ? 'contained' : 'outlined'} color='primary'>{label}</Button>
                else 
                    return <Button onClick={() => handleClick()} variant={statusId === original_status_id ? 'contained' : 'outlined'} disabled>{label}</Button>

            } else {

                return <Button onClick={() => handleClick()} variant={statusId === original_status_id ? 'contained' : 'outlined'} style={{color: color, borderColor: color}}>{label}</Button>
            }
        }
    }

    return (
      <>
        <Grid style={style} container spacing={2}>
          <Grid item>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <BotonStatus
                original_status_id="88"
                label="Lead"
                estados_activan={["88", "126","231"]}
              />
              <BotonStatus
                original_status_id="126"
                label="Repetido"
                estados_activan={["88", "89", "126", "230","231"]}
              />
              <BotonStatus
                original_status_id="231"
                label="Spam"
                estados_activan={["88", "126", "230", "231"]}
              />
            </ButtonGroup>
          </Grid>

          <Grid item>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <BotonStatus
                original_status_id="92"
                label="Llamad@ N/C"
                estados_activan={["88", "126", "92"]}
              />
              <BotonStatus
                original_status_id="89"
                label="Contactado"
                estados_activan={["88", "126", "91", "92", "89", "164", "186", "187"]}
              />
              <BotonStatus
                original_status_id="230"
                label="Gestionado en origen"
                estados_activan={["88", "126", "91", "92", "89", "186", "187"]}
              />
            </ButtonGroup>
          </Grid>

          <Grid item>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <BotonStatus
                original_status_id="93"
                label="Septiembre"
                estados_activan={[
                  "93",
                  "89",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "172",
                ]}
              />
              <BotonStatus
                original_status_id="97"
                label="Pendiente de pago"
                estados_activan={[
                  "93",
                  "94",
                  "89",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "172",
                ]}
              />
              <BotonStatus
                original_status_id="95"
                label="Nada que ofrecer"
                estados_activan={[
                  "93",
                  "89",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "172",
                ]}
              />
              <BotonStatus
                original_status_id="96"
                label="Perdida"
                estados_activan={[
                  "93",
                  "89",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "172",
                ]}
              />
              <BotonStatus
                original_status_id="91"
                label="Stand By"
                estados_activan={[
                  "93",
                  "89",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "172",
                ]}
              />
              <BotonStatus
                original_status_id="164"
                label="Lista de espera"
                estados_activan={[
                  "93",
                  "89",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "172",
                ]}
              />
              {/* <BotonStatus
                original_status_id="172"
                label="Enero intensivo"
                estados_activan={["93", "89", "97", "95", "96", "91", "164"]}
              /> */}
            </ButtonGroup>
          </Grid>

          <Grid item>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              {/* <BotonStatus
                original_status_id="186"
                label="Abril"
                estados_activan={[
                  "93",
                  "89",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "172",
                  "186",
                  "187",
                ]}
              /> */}
              {/* <BotonStatus
                original_status_id="187"
                label="Septiembre secundaria"
                estados_activan={[
                  "93",
                  "89",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "172",
                  "186",
                  "187",
                ]}
              /> */}
            </ButtonGroup>
          </Grid>

          <Grid item>
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <BotonStatus
                original_status_id="94"
                label="Alumno"
                estados_activan={[
                  "93",
                  "94",
                  "127",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                  "186",
                  "187",
                ]}
              />
              <BotonStatus
                original_status_id="127"
                label="Reserva"
                estados_activan={[
                  "93",
                  "94",
                  "127",
                  "97",
                  "95",
                  "96",
                  "91",
                  "164",
                ]}
              />
            </ButtonGroup>
          </Grid>

          {statusId === "94" ||
          statusId === "97" ||
          statusId === "127" ||
          statusId === "172" ? (
            <Grid item>
              <ButtonGroup aria-label="outlined primary button group">
                <BotonStatus
                  color={red[500]}
                  original_status_id="0"
                  label="Baja"
                />
              </ButtonGroup>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid style={{marginBottom: "20px"}}>
          <Accordion style={{border: "none"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{backgroundColor: grey[100]}}
            >
              Estados del alumno (Explicación)
            </AccordionSummary>
            <AccordionDetails style={{flexDirection:"column"}}>
                <div><b>Lead</b>: Oportunidad de venta NO se ha intentado contactar.<br /></div>
                <div><b>Repetido</b>: El alumno que tenga este estado tiene más fichas. La ficha marcada como repetida no es la ficha principal<br /></div>
                <div><b>Llamada N/C</b>: Se ha intentado llamar, pero no hay respuesta<br /></div>
                <div><b>Contactado</b>: Se ha contactado a través de teléfono, email o WhatsApp<br /></div>
                <div><b>Septiembre</b>: Hemos contactado y quiere que le llamemos en septiembre.<br /></div>
                <div><b>Pendiente de pago</b>: Se ha enviado el enlace de pago y todavía no ha pagado.<br /></div>
                <div><b>Nada que ofrecer</b>: No preparamos la especialidad o comunidad en la que se presenta.<br /></div>
                <div><b>Perdida</b>: No está interesada o no hemos podido contactar.<br /></div>
                <div><b>Stand By</b>: Se lo esta pensando (No creo que sea necesario)<br /></div>
                <div><b>Lista de espera</b>: Plazas agotadas de la especialidad en la que está interesada.<br /></div>
                {/* <div><b>Enero intensivo</b>: Preparación FAST (Ahora mismo no es necesario).<br /></div> */}
                {/* <div><b>Abril secundaria</b>: No es necesario<br /></div> */}
                {/* <div><b>Septiembre secundaria</b>: No es necesario, con septiembre y los filtros es suficiente.<br /></div> */}
                <div><b>Alumno</b>: Alumno matriculado y pagado la reserva/cuota.<br /></div>
                <div><b>Reserva</b>: <span style={{color: "#FF0000"}}>??</span><br /></div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </>
    );
};

export default AlumnosStatus;