import React from 'react'

const TemperaturaAlumno = ({temperaturaId, showLabel = true}) => {
    return (
        <>
            {/* 101	Frío */}
            { temperaturaId == 101 ? <>🔵 { showLabel && "Frío" }</> : <></>}
            {/* 100	Templado */}
            { temperaturaId == 100 ? <>🟡 { showLabel && "Templado"}</> : <></>}
            {/* 99	Caliente */}
            { temperaturaId == 99  ? <>🟠 { showLabel && "Caliente"}</> : <></>}
            {/* 98	Muy caliente */}
            { temperaturaId == 98  ? <>🔥 { showLabel && "Muy caliente"}</> : <></>}
            {/* 225	Consulta */}
            { temperaturaId == 225 ? <>❓ { showLabel && "Consulta"}</> : <></>}

        </>
    )
  
}

export default TemperaturaAlumno
