import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import { getEstadisticas } from '../../../../services/alumnos';
import { Box, Button, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function RingoverEstadisticasContent() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [estadisticas, setEstadisticas] = useState([])
    const [datosAgrupados, setDatosAgrupados] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando estadisticas")

        const usuario_id = usuarioSeleccionado.id

        getEstadisticas({ usuario_id }).then((response) => {
            setLoading(false)

            response && setEstadisticas([response.estadisticas])

        });

    }, [])
    useEffect(() => {
        console.log(estadisticas[0])
        setDatosAgrupados(

            estadisticas.map((estadistica) => estadistica)

        )

        console.log(datosAgrupados)
        // const datosAgrupados = estadisticas.reduce((acc, item) => {
            
        //     console.log(acc, item)
            
        // }, new Map());

        // console.log(datosAgrupados)
    }, [estadisticas])

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const Contenido = () => {

        return (
        <>
            <TituloPagina titulo={"Uso de Ringover por comercial"} />

            <Box style={{paddingRight: 20, paddingLeft: 20}}>
            <Button variant='outlined'  href="https://gestion.innovatiio.com/api/procesos/ringover_actualizacion_llamadas" target="_blank" rel="noreferrer" className={classes.enlace}>Sincronizar datos con Ringover ahora</Button>
            {
                Object.entries(datosAgrupados).map(([index, usuarios]) => {
                    return Object.entries(usuarios).map(([usuario_id, datos]) => {
                        const usuario_nombre = datos[0]['usuario_nombre']
                        return (
                          <>
                            <h3>{`${usuario_nombre}`}</h3>
                            <TableContainer component={Paper} style={{width: 'fit-content' }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  {datos.map((item) => (
                                    <TableCell align="right">
                                      {item.fecha}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Total minutos</TableCell>
                                  {datos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.total_minutos) > 0 ? parseInt(item.total_minutos) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Total llamadas</TableCell>
                                  {datos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.total_llamadas) > 0 ? parseInt(item.total_llamadas) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Llamadas menos 3 min</TableCell>
                                  {datos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.llamadas_menos_tres_minutos) > 0 ? parseInt(item.llamadas_menos_tres_minutos) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                                <TableRow>
                                  <TableCell>Llamadas más 3 min</TableCell>
                                  {datos.map((item) => (
                                    <TableCell align="right">
                                      { parseInt(item.llamadas_mas_tres_minutos) > 0 ? parseInt(item.llamadas_mas_tres_minutos) : "" }
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableBody>
                            </TableContainer>
                            {/* {JSON.stringify(datos)} */}
                            {/* <h3>{`Usuario ${periodo}`}</h3>
                            <TableContainer component={Paper} style={{width: 'fit-content' }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Nombre</TableCell>
                                  <TableCell>Apellido</TableCell>
                                  <TableCell align="right">
                                    Número de llamadas
                                  </TableCell>
                                  <TableCell align="right">
                                    Duración (horas)
                                  </TableCell>
                                  <TableCell align="right">
                                    Ventas
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {datos.map((item) => (
                                  <TableRow
                                    key={item.user_id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {item.firstname}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {item.lastname}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                      {item.num_llamadas}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                      {item.duracion_horas}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                      {item.ventas}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </TableContainer> */}
                          </>
                        );
                    })
                })
            }
            </Box>
        </>
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
