import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import Divider from '@mui/material/Divider';
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import TemplateDashboard from "../../../components/TemplateDashboard";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import * as yup from "yup";
import TemplateForm from "../../../components/TemplateForm/TemplateForm";
import {
  enviar_demo,
  getAlumno,
  insertar_alumno,
  recomendarAlumnoPrincipal,
  setGestionarEnOrigen,
  update_alumno,
  updateAlumnoPrincipalId,
} from "../../../services/alumnos";
import SwipeableViews from "react-swipeable-views";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getListaDetalle } from "../../../services/lista_detalle";
import { getComerciales } from "../../../services/comerciales";
import AlumnosNotasForm from "./AlumnosNotasForm";
import AlumnosProductoForm from "./AlumnosProductoForm";
import Swal from "sweetalert2";
import moment from "moment";
import Moment from "react-moment";
import esLocale from "date-fns/locale/es";
import AlumnoStatus from "./AlumnoStatus";
import { getTutores } from "../../../services/tutores";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import { Alert, CircularProgress, Stack } from "@mui/material";
import AlumnosNotasSeguimientoForm from "./AlumnosNotasSeguimientoForm";
import AlumnosTagsForm from "./AlumnosTagsForm";
import TemperaturaAlumno from "../../../components/TemperaturaAlumno";

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
// const useStyles = makeStyles((theme) => ({
//   root: {
//     marginTop: 90,
//     // display: 'flex',
//     // flexWrap: 'wrap',
//   },
//   textField: {
//     // marginLeft: theme.spacing(1),
//     // marginRight: theme.spacing(1),
//   },
//   keyboardDatePicker: {
//     // marginLeft: theme.spacing(1),
//     // marginRight: theme.spacing(1),
//   },
//   formulario: {
//     margin: 8,
//   },
//   input: {
//     display: "none",
//   },
//   chipCancelado: {
//     backgroundColor: red[100],
//   },
// }));

/* -------------------------------------------------------------------------- */
/*                            Esquema de valicación                           */
/* -------------------------------------------------------------------------- */
const validationSchema = yup.object({
  nombre: yup.string().required("Obligatorio"),
  // correo_electronico: yup.string().email().required("Correo es requerido"),
  comercial_id: yup.number().required("Obligatorio"),
  temperatura_id: yup.mixed().required("Obligatorio"),
  // estado_id: yup.number().required("Temperatura es requerido"),
  como_conocido_id: yup.mixed().required("Obligatorio"),
  comunidad_autonoma_id: yup.mixed().required("Obligatorio"),
  oposicion_id: yup.mixed().required("Obligatorio"),
  especialidad_id: yup.mixed().required("Obligatorio"),
});

const AlumnosForm = () => {
  // const classes = useStyles();
  const { id: alumno_id } = useParams();

  let history = useHistory();
  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  const [loading, setLoading] = useState(false);
  const [alumno, setAlumno] = useState(undefined);
  const [tabSelected, setTabSelected] = React.useState(0);

  const [tutores, setTutores] = useState(undefined);
  // const [temperaturas, setTemperaturas] = useState(undefined);
  const [estados, setEstados] = useState(undefined);
  const [comoConocidos, setComoConocidos] = useState(undefined);
  const [comunidadesAutonomas, setComunidadesAutonomas] = useState(undefined);
  const [oposiciones, setOposiciones] = useState(undefined);
  const [especialidades, setEspecialidades] = useState(undefined);
  const [comerciales, setComerciales] = useState(undefined);
  const [motivosBaja, setMotivosBaja] = useState(undefined);
  const [motivosPerdida, setMotivosPerdida] = useState(undefined);
  const [listaDedicacion, setListaDedicacion] = useState(undefined);
  const [situaciones, setSituaciones] = useState(undefined);
  const [showFormProducto, setShowFormProducto] = useState(false);

  const [alumnoProductoId, setAlumnoProductoId] = useState(undefined);
  const [alumnoProductoFormTipo, setAlumnoProductoFormTipo] = useState(undefined);
  const [alumnoPrincipalRecomendado, setAlumnoPrincipalRecomendado] = useState(undefined);

  /* ----------------------------- Estado inicial ----------------------------- */
  const [initialValues, setInitialValues] = useState({
    alumno_id: "",
    nombre: "",
    fecha_alta: null,
    temperatura_id: "",
    estado_id: "",
    como_conocido_id: "",
    comunidad_autonoma_id: "",
    oposicion_id: "",
    especialidad_id: "",
    correo_electronico: "",
    telefono: "",
    es_demo: "",
    ultimo_contacto: null,
    proximo_contacto: null,
    alumno_desde: null,
    fecha_baja: null,
    tutor: "",
    comercial_id: "",
    situacion_id: "",
    posible_alta_id: "",
    observaciones_formulario: "",
    mensaje_formulario: "",
    dni: "",
    direccion: "",
    codigo_postal: "",
    ciudad: "",
    provincia: "",
    pais: "",
    formulario_nombre: "",
    tutor_preferido_id: "",
    fecha_posible_baja: null,
    motivo_baja: "",
    motivo_baja_id: "",
    importe_a_cuenta: 0,
    a_cuenta_toda_preparacion: "",
    es_baja_tutea: "",
    es_baja_portal_alumno: "",

    num_convocatorias: 0,
    ultima_convocatoria_year: 0,
    fecha_nacimiento: null,
    horas_estudio: 0,
    motivo_perdida_id: "",
    dedicacion_id: "",
  });

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const usuario_id = usuarioSeleccionado.id;

    setLoading(true);

    alumno_id ?
      getAlumno({ usuario_id, alumno_id }).then((response) => {
        response.alumno && setAlumno(response.alumno);

        formik.setValues({
          alumno_id: response.alumno.alumno_id,
          nombre: response.alumno.nombre,
          fecha_alta: response.alumno.fecha_alta,
          temperatura_id: response.alumno.temperatura_id,
          estado_id: response.alumno.status_id,
          como_conocido_id: response.alumno.como_conocido_id,
          comunidad_autonoma_id: response.alumno.comunidad_autonoma_id,
          oposicion_id: response.alumno.oposicion_id,
          especialidad_id: response.alumno.especialidad_id,
          correo_electronico: response.alumno.correo_electronico,
          telefono: response.alumno.telefono,
          es_demo: response.alumno.es_demo,
          ultimo_contacto: response.alumno.ultimo_contacto,
          proximo_contacto: response.alumno.proximo_contacto,
          fecha_baja: response.alumno.fecha_baja,
          alumno_desde: response.alumno.alumno_desde,
          tutor: response.alumno.tutor,
          comercial_id: response.alumno.comercial_id,
          posible_alta_id: response.alumno.posible_alta_id,
          situacion_id: response.alumno.situacion_id,
          formulario_observaciones:
            response.alumno.formulario_observaciones === undefined
              ? ""
              : response.alumno.formulario_observaciones,
          mensaje_formulario:
            response.alumno.mensaje_formulario === undefined
              ? ""
              : response.alumno.mensaje_formulario,
          dni: response.alumno.dni,
          direccion: response.alumno.direccion,
          codigo_postal: response.alumno.codigo_postal,
          ciudad: response.alumno.ciudad,
          provincia: response.alumno.provincia,
          pais: response.alumno.pais,
          formulario_nombre: response.alumno.formulario_nombre,
          tutor_preferido_id: response.alumno.tutor_preferido_id,
          fecha_posible_baja: response.alumno.fecha_posible_baja,
          motivo_baja: response.alumno.motivo_baja,
          motivo_baja_id: response.alumno.motivo_baja_id,
          importe_a_cuenta: response.alumno.importe_a_cuenta,
          a_cuenta_toda_preparacion: response.alumno.a_cuenta_toda_preparacion,
          es_baja_tutea: response.alumno.es_baja_tutea,
          es_baja_portal_alumno: response.alumno.es_baja_portal_alumno,

          num_convocatorias: response.alumno.num_convocatorias,
          ultima_convocatoria_year: response.alumno.ultima_convocatoria_year,
          fecha_nacimiento: response.alumno.fecha_nacimiento,
          horas_estudio: response.alumno.horas_estudio,
          motivo_perdida_id: response.alumno.motivo_perdida_id,
          dedicacion_id: response.alumno.dedicacion_id,
        });

          recomendarAlumnoPrincipal({
            usuario_id: usuario_id,
            alumno_id: response.alumno.id,
          }).then((response) => {
            setAlumnoPrincipalRecomendado(response.alumno_recomendado);

            setLoading(false);
          });
      })
    :
      setLoading(false);

    getListaDetalle({ usuario_id: usuario_id, lista_id: 8 }).then(
      (response) => {
        response.lista_detalle && setEstados(response.lista_detalle);
      }
    );
  }, []);

  useEffect(() => {
    const usuario_id = usuarioSeleccionado.id;

    alumno_id ?
      getAlumno({ usuario_id, alumno_id }).then((response) => {
        response.alumno && setAlumno(response.alumno);

        formik.setValues({
          alumno_id: response.alumno.alumno_id,
          nombre: response.alumno.nombre,
          fecha_alta: response.alumno.fecha_alta,
          temperatura_id: response.alumno.temperatura_id,
          estado_id: response.alumno.status_id,
          como_conocido_id: response.alumno.como_conocido_id,
          comunidad_autonoma_id: response.alumno.comunidad_autonoma_id,
          oposicion_id: response.alumno.oposicion_id,
          especialidad_id: response.alumno.especialidad_id,
          correo_electronico: response.alumno.correo_electronico,
          telefono: response.alumno.telefono,
          es_demo: response.alumno.es_demo,
          ultimo_contacto: response.alumno.ultimo_contacto,
          proximo_contacto: response.alumno.proximo_contacto,
          alumno_desde: response.alumno.alumno_desde,
          fecha_baja: response.alumno.fecha_baja,
          tutor: response.alumno.tutor,
          comercial_id: response.alumno.comercial_id,
          posible_alta_id: response.alumno.posible_alta_id,
          situacion_id: response.alumno.situacion_id,
          formulario_observaciones:
            response.alumno.formulario_observaciones === undefined
              ? ""
              : response.alumno.formulario_observaciones,
          mensaje_formulario:
            response.alumno.mensaje_formulario === undefined
              ? ""
              : response.alumno.mensaje_formulario,
          dni: response.alumno.dni,
          direccion: response.alumno.direccion,
          codigo_postal: response.alumno.codigo_postal,
          ciudad: response.alumno.ciudad,
          provincia: response.alumno.provincia,
          pais: response.alumno.pais,
          formulario_nombre: response.alumno.formulario_nombre,
          tutor_preferido_id: response.alumno.tutor_preferido_id,
          fecha_posible_baja: response.alumno.fecha_posible_baja,
          motivo_baja: response.alumno.motivo_baja,
          motivo_baja_id: response.alumno.motivo_baja_id,
          importe_a_cuante: response.alumno.importe_a_cuenta,
          a_cuenta_toda_preparacion: response.alumno.a_cuenta_toda_preparacion,
          es_baja_tutea: response.alumno.es_baja_tutea,
          es_baja_portal_alumno: response.alumno.es_baja_portal_alumno,
          num_convocatorias: response.alumno.num_convocatorias,
          ultima_convocatoria_year: response.alumno.ultima_convocatoria_year,
          fecha_nacimiento: response.alumno.fecha_nacimiento,
          horas_estudio: response.alumno.horas_estudio,
          motivo_perdida_id: response.alumno.motivo_perdida_id,
          dedicacion_id: response.alumno.dedicacion_id,
        });
      })
    :
      setLoading(false);

    // const [comerciales, setComerciales] = useState(undefined)
  }, [showFormProducto]);

  /* -------------------------------------------------------------------------- */
  /*                                  UseFormik                                 */
  /* -------------------------------------------------------------------------- */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      if (alumno === undefined) {
        insertar_alumno({
          usuario_id: usuarioSeleccionado.id,
          campos: values,
        }).then((response) => {
          setLoading(false);

          Swal.fire("Datos insertados correctamente", "", "info");

          history.push("/alumnos/detalle/" + response.alumno_id);
        });
      } else {
        values.alumno_id = alumno_id;

        console.log("Actualiza");

        update_alumno({
          usuario_id: usuarioSeleccionado.id,
          campos: values,
        }).then((response) => {
          Swal.fire("Datos actualizados correctamente", "", "info");

          setLoading(false);
        });
      }
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                   Handler                                  */
  /* -------------------------------------------------------------------------- */

  /* ----------------------------- handleChangeTab ---------------------------- */
  const handleChangeTab = (event, newValue) => {
    setTabSelected(newValue);
  };

  /* -------------------------- handleChangeTabIndex -------------------------- */
  const handleChangeTabIndex = (index) => {
    setTabSelected(index);
  };

  /* ---------------------------- handleProductoAdd --------------------------- */
  const handleProductoAdd = (tipo) => {
    setAlumnoProductoFormTipo(tipo);
    setAlumnoProductoId(undefined);
    setShowFormProducto(true);
  };

  /* ------------------------- handleProductoDetallex ------------------------- */
  const handleProductoDetalle = (alumno_producto_id) => {
    setAlumnoProductoId(alumno_producto_id);
    setShowFormProducto(true);
  };

  /* --------------------------- handleAlumnoDetalle -------------------------- */
  const handleAlumnoDetalle = (id) => {
    history.push(id);
    history.go(0);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Columnas                                  */
  /* -------------------------------------------------------------------------- */
  const columnas_woo_order = [
    { field: "order_id", headerName: "Order ID", minWidth: 150, flex: 1 },
    { field: "order_items", headerName: "Descripción", minWidth: 150, flex: 1 },
    { field: "order_total", headerName: "Importe", minWidth: 150, flex: 1 },
    { field: "post_date", headerName: "Fecha", minWidth: 150, flex: 1 },
    { field: "paid_date", headerName: "Fecha pago", minWidth: 150, flex: 1 },
    {
      field: "post_status",
      width: 150,
      headerName: "Estado",
      description: "Estado",
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.value === "wc-refunded" ? (
              <Chip
                style={{ backgroundColor: red[100] }}
                className="chipReembolsado"
                size="small"
                label="Reembolsado"
              />
            ) : (
              ""
            )}
            {cellValues.value === "wc-cancelled" ? (
              <Chip
                style={{ backgroundColor: red[100] }}
                className="chipCancelado"
                size="small"
                label="Cancelado"
              />
            ) : (
              ""
            )}
            {cellValues.value === "wc-completed" ? (
              <Chip
                style={{ backgroundColor: green[100] }}
                size="small"
                label="Completado"
              />
            ) : (
              ""
            )}
            {cellValues.value !== "wc-cancelled" &&
            cellValues.value !== "wc-completed" &&
            cellValues.value !== "wc-refunded" ? (
              <Chip
                style={{ backgroundColor: yellow[200] }}
                size="small"
                label={cellValues.value}
              />
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];
  const columnas_alumnos_duplicados = [
    { field: "id", headerName: "ID", minWidth: 150 },
    { field: "nombre", headerName: "Nombre", minWidth: 350, flex: 1, 
        renderCell: (cellValues) => {
            return (
                <>
                    {cellValues.value} {cellValues.id === cellValues.row.alumno_principal_id && <Chip label="Principal" size="small" style={{marginLeft: 10,backgroundColor: red[500], color: 'white'}}  />}
                </>
            )
        }, 
    },
    {
      field: "correo_electronico",
      headerName: "Correo electronico",
      width: 350,
    },
    {
      field: "created",
      width: 200,
      headerName: "Añadido",
      description: "Añadido",
      type: "date",
      valueFormatter: (params) => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A");
      },
      renderCell: (cellValues) => {
        return cellValues.value !== null &&
          cellValues.value !== "0000-00-00 00:00:00" ? (
          <Moment format="DD/MM/YYYY HH:mm:ss">{cellValues.value}</Moment>
        ) : (
          <></>
        );
      },
    },
    { field: "temperatura_nombre", headerName: "Temperatura", minWidth: 120 },
    {
      field: "comercial_nombre",
      headerName: "Comercial",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "comunidad_autonoma_nombre",
      headerName: "Comunidad",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "oposicion_nombre",
      headerName: "Oposición",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "especialidad_nombre",
      headerName: "Especialidad",
      minWidth: 10,
      flex: 1,
    },
    {
      field: "como_conocido_nombre_lista",
      headerName: "Como conocido",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          // icon={<SendIcon />}
          label="Gestionar en origen"
          onClick={(item) => {
            setGestionarEnOrigen({
              usuario_id: usuarioSeleccionado.id,
              alumno_id: alumno_id,
              alumno_origen_id: item.id,
            }).then((response) => {
              history.push(`/alumnos/detalle/${params.id}`);
              history.go(0);
            });
          }}
          showInMenu
        />,
      ],
    },
  ];
  const columnas_notas = [
    { field: "nota", headerName: "Nota", flex: 1 },
    { field: "created", headerName: "Creado", minWidth: 150, flex: 1 },
  ];
  const columnas_productos = [
    { field: "producto_nombre", headerName: "Producto", flex: 1 },
    { field: "num_cuotas", headerName: "Num. coutas", flex: 1 },
    { field: "forma_pago_nombre", headerName: "Forma de pago", flex: 1 },
    { field: "importe", headerName: "Importe", flex: 1 },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          // icon={<SendIcon />}
          label="Abrir"
          onClick={() => handleProductoDetalle(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];
  const columnas_observaciones = [
    { field: "id", headerName: "Ficha alumno", width: 120 },
    { field: "mensaje_formulario", headerName: "Mensaje", flex: 1 },
    { field: "formulario_observaciones", headerName: "Observaciones", flex: 1 },
    { field: "created", headerName: "Fecha", flex: 1 },
    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 80,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       // icon={<SendIcon />}
    //       label="Abrir"
    //       onClick={() => handleProductoDetalle(params.row.id)}
    //       showInMenu
    //     />,
    //   ],
    // },
  ];

  /* --------------------------------- Return --------------------------------- */
  return (
    <>
      <TemplateDashboard showBack={true}>
        <TemplateForm
          tituloForm={
            alumno === undefined ? "Añadir alumno" : "Actualizar alumno"
          }
          onSubmit={formik.handleSubmit}
        >
          {/* ---------------------------- Alumno Principal ---------------------------- */}
          <Grid item xs={12} md={12}>
            <HeaderAlumnoPrincipal 
              alumno={alumno}
              alumnoPrincipalRecomendado={alumnoPrincipalRecomendado} 
              usuarioSeleccionado={usuarioSeleccionado} 
              setAlumno={setAlumno}
            />
          </Grid>

          {/* ---------------------------- Estado del alumno --------------------------- */}
          { loading && <LoadingAlumno texto={"Cargando estados o nuevo alumno"}/> }
          {
            alumno && !loading &&
            <AlumnoStatus
              style={{ paddingTop: 30, paddingBottom: 30 }}
              alumno_id={alumno.id}
              status_id={alumno.status_id}
              fecha_baja={alumno.fecha_baja}
            />
           
          }

          {/* ------------------------- Contenedor datos Leads ------------------------- */}
          <ContainerSeccion titulo={
            <Box display="flex" justifyContent={"space-between"} width={"100%"}>

              <Box>
                <span style={{marginRight:10}}>Datos del LEAD</span>
                {
                  alumno?.status_id !== "94" && 
                  <TemperaturaAlumno
                    temperaturaId={formik.values.temperatura_id}
                    showLabel={false}
                  />
                }
              </Box>

              <Box>

                <TagTelefonoDuplicado esTelefonoDuplicado={alumno?.es_telefono_duplicado} />

                <TagFichasDuplicadas numFichas={alumno?.alumnos_duplicados.length} />
                
                <TagEsAlumno statusId={alumno?.status_id} es_baja={alumno?.es_baja} />

                <LinkPortalAlumno
                  statusId={alumno?.status_id}
                  correoElectronico={alumno?.correo_electronico}
                />

              </Box>

            </Box>
          }>
            { loading && <LoadingAlumno texto={"Cargando datos del Leads"}/> }
            
            <FormDatosLead formik={formik} alumno={alumno} setAlumno={setAlumno} />

          </ContainerSeccion>
            

          {/* --------------------- Contenedor datos de facturación -------------------- */}
          <ContainerSeccion titulo={
            <>
              <span style={{marginRight:10}}>Datos de facturación</span>
            </>
          }>
            { loading && <LoadingAlumno texto={"Cargando datos de facturación"}/> }
            <FormDatosFacturacion formik={formik} />
          </ContainerSeccion>

          {/* ----------------------- Contenedor datos del alumno ---------------------- */}
          <ContainerSeccion titulo={
          <Box display="flex" justifyContent={"space-between"} width={"100%"}>
              <span style={{marginRight: 10}}>Datos de alumno</span>
                  
                <LinkPortalAlumno
                  statusId={alumno?.status_id}
                  correoElectronico={alumno?.correo_electronico}
                />
            </Box>
          }>
            { loading && <LoadingAlumno texto={"Cargando datos del alumno"}/> }
            <FormDatosAlumno formik={formik} alumno={alumno} />
          </ContainerSeccion>

        </TemplateForm>

        {alumno_id && (
          <Box sx={{ marginX: 2, marginY: 12 }}>
            <Paper>
              <AppBar position="static" color="default">
                <Tabs
                  value={tabSelected}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  // variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Acciones comerciales" {...a11yProps(0)} />
                  <Tab label="Productos" {...a11yProps(1)} />
                  <Tab label="Fichas duplicadas" {...a11yProps(2)} />
                  <Tab label="Comentarios" {...a11yProps(3)} />
                  <Tab label="Seguimiento" {...a11yProps(4)} />
                  <Tab label="Tags" {...a11yProps(5)} />
                  <Tab label="Pedidos web" {...a11yProps(6)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={"x"}
                index={tabSelected}
                onChangeIndex={handleChangeTabIndex}
              >
                {/* Acciones comerciales */}
                <TabPanel value={tabSelected} index={0}>
                  {alumno && (
                    <AlumnosNotasForm
                      alumno_id={alumno_id}
                      notas={alumno.notas}
                    />
                  )}
                </TabPanel>

                {/* Productos */}
                <TabPanel value={tabSelected} index={1}>
                  {showFormProducto ? (
                    <AlumnosProductoForm
                      alumnoProductoFormTipo={alumnoProductoFormTipo}
                      alumnoId={alumno_id}
                      alumnoProductoId={alumnoProductoId}
                      setShowFormProducto={setShowFormProducto}
                      setAlumno={setAlumno}
                    />
                  ) : (
                    <>
                      <Stack spacing={2} direction="row">
                        {/* <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ marginTop: "16px", marginBottom: "16px" }}
                          onClick={() => handleProductoAdd("Producto")}
                        >
                          Añadir producto
                        </Button> */}
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ marginTop: "16px", marginBottom: "16px" }}
                          onClick={() => handleProductoAdd("ProductoCuotasAutomaticas")}
                        >
                          Añadir producto (cuotas automáticas)
                        </Button>
                      </Stack>

                      {alumno && (
                        <div style={{ height: 500, width: "100%" }}>
                          <DataGrid
                            rows={alumno.productos}
                            columns={columnas_productos}
                            onRowDoubleClick={(evento) =>
                              handleProductoDetalle(evento.id)
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                </TabPanel>

                {/* Fichas duplicadas */}
                <TabPanel value={tabSelected} index={2}>
                  {alumno && (
                    <div style={{ height: 500, width: "100%" }}>
                      <DataGrid
                        rows={alumno.alumnos_duplicados}
                        columns={columnas_alumnos_duplicados}
                        onRowDoubleClick={(evento) =>
                          handleAlumnoDetalle(evento.id)
                        }
                      />
                    </div>
                  )}
                </TabPanel>

                {/* Comentarios */}
                <TabPanel value={tabSelected} index={3}>
                {alumno && (
                    <div style={{ height: 500, width: "100%" }}>
                        <DataGrid
                        rows={alumno.observaciones_alumno}
                        columns={columnas_observaciones}
                        onRowDoubleClick={(evento) =>
                            handleAlumnoDetalle(evento.id)
                        }
                        />
                    </div>
                    )}
                </TabPanel>

                {/* Seguimiento */}
                <TabPanel value={tabSelected} index={4}>
                  {alumno && (
                    <AlumnosNotasSeguimientoForm
                      alumno_id={alumno_id}
                      notas_seguimiento={alumno.notas_seguimiento}
                    />
                  )}
                </TabPanel>

                {/* Tags */}
                <TabPanel value={tabSelected} index={5}>
                  {alumno && (
                    <AlumnosTagsForm
                      alumno_id={alumno_id}
                      tagsAlumno={alumno.tags_alumno}
                    />
                  )}
                </TabPanel>

                {/* Pedidos web */}
                <TabPanel value={tabSelected} index={6}>
                  {alumno && (
                    <div
                      style={{ height: 500, width: "100%", padding: "0 15px" }}
                    >
                      <DataGrid
                        rows={alumno.woo_order}
                        columns={columnas_woo_order}
                        // rowHeight={30}
                        // components={{ Toolbar: QuickSearchToolbar }}
                        // onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                        // componentsProps={{
                        // toolbar: {
                        // value: searchText,
                        //     onChange: (event) => requestSearch(event.target.value),
                        //     clearSearch: () => requestSearch(''),
                        // },
                        // }}
                      />
                    </div>
                  )}
                </TabPanel>

              </SwipeableViews>
            </Paper>
          </Box>
        )}
      </TemplateDashboard>
    </>
  );
};

//#region COMPONENTES

const LoadingAlumno = ({texto}) => {
  return (
    <Box my={6} style={{ textAlign: "center" }}>
      <CircularProgress size={10} /> {texto} ...
    </Box>
  );
};
/* -------------------------- TagTelefonoDuplicado -------------------------- */
const TagTelefonoDuplicado = ({ esTelefonoDuplicado }) => {
  return (
    esTelefonoDuplicado === "1" && (
      <Chip
        style={{ marginLeft: 10, backgroundColor: red[400], color: "white" }}
        size="small"
        label="Telefono duplicado"
      />
    )
)}

/* --------------------------- TagFichasDuplicadas -------------------------- */
const TagFichasDuplicadas = ({ numFichas }) => {
  return ( 
    numFichas > 1 && (
      <Chip
        style={{ marginLeft: 10, backgroundColor: red[400], color: "white" }}
        size="small"
        label="Tiene fichas duplicadas"
      />
    )
)}

/* ------------------------------- TagEsAlumno ------------------------------ */
const TagEsAlumno = ({ statusId, es_baja }) => {
  return ( 
    statusId === "94" && es_baja === "0" && (
      <Chip
        style={{ marginLeft: 10, backgroundColor: green[400], color: "white" }}
        size="small"
        label="ES ALUMNO"
      />
    )
)}

/* ---------------------------- LinkPortalAlumno ---------------------------- */
const LinkPortalAlumno = ({ tipo, statusId, correoElectronico }) => {
  return (
    statusId === "94" && (
      <Link
        style={{ 
          fontSize: 14,
          color: "#0645AD",
          marginLeft: 10,
        }}
        href={`https://alumno.innovatiio.com/login?correo=${correoElectronico}`}
        target="_blank"
      >
        [ Portal del alumno ]
      </Link>
    )
)}

/* ------------------------------ FormDatosLead ----------------------------- */
const FormDatosLead = ({ formik, alumno, setAlumno }) => {
  const [temperaturas, setTemperaturas] = useState([]);
  const [comoConocidos, setComoConocidos] = useState([]);
  const [comunidadesAutonomas, setComunidadesAutonomas] = useState([]);
  const [oposiciones, setOposiciones] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [situaciones, setSituaciones] = useState([]);
  const [comerciales, setComerciales] = useState([]);
  const [motivosPerdida, setMotivosPerdida] = useState([]);
  const [posibleAlta, setPosibleAlta] = useState([]);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  useEffect(() => {
    const usuario_id = usuarioSeleccionado.id;

    // Temperaturas
    getListaDetalle({ usuario_id: usuario_id, lista_id: 9 }).then(
      (response) => {
        response.lista_detalle && setTemperaturas(response.lista_detalle);
      }
    );

    // Como nos ha conocido
    getListaDetalle({ usuario_id: usuario_id, lista_id: 6 }).then(
      (response) => {
        response.lista_detalle && setComoConocidos(response.lista_detalle);
      }
    );

    // Comunidades autonomas
    getListaDetalle({ usuario_id: usuario_id, lista_id: 3 }).then(
      (response) => {
        response.lista_detalle &&
          setComunidadesAutonomas(response.lista_detalle);
      }
    );

    // Oposiciones
    getListaDetalle({ usuario_id: usuario_id, lista_id: 2 }).then(
      (response) => {
        response.lista_detalle && setOposiciones(response.lista_detalle);
      }
    );

    // Especialidades
    getListaDetalle({ usuario_id: usuario_id, lista_id: 1 }).then(
      (response) => {
        response.lista_detalle && setEspecialidades(response.lista_detalle);
      }
    );

    // Situaciones
    getListaDetalle({ usuario_id: usuario_id, lista_id: 7 }).then(
      (response) => {
        response.lista_detalle && setSituaciones(response.lista_detalle);
      }
    );

    // Comerciales
    getComerciales({ usuario_id: usuario_id }).then((response) => {
      response.comerciales && setComerciales(response.comerciales);
    });

    // Motivos perdida
    getListaDetalle({ usuario_id: usuario_id, lista_id: 18 }).then(
      (response) => {
        response.lista_detalle && setMotivosPerdida(response.lista_detalle);
      }
    );
    
    // Posible alta
    getListaDetalle({ usuario_id: usuario_id, lista_id: 29 }).then(
      (response) => {
        response.lista_detalle && setPosibleAlta(response.lista_detalle);
      }
    );
  }, []);

  return (
    <Grid container spacing={2}>

      {/* Nombre */}
      <Grid item xs={12} md={5}>
        <TextField
          id="nombre"
          name="nombre"
          label="Nombre"
          placeholder="Introduce el nombre"
          fullWidth
          margin="normal"
          value={formik.values.nombre}
          onChange={formik.handleChange}
          variant="outlined"
          error={formik.touched.nombre && Boolean(formik.errors.nombre)}
          helperText={formik.touched.nombre && formik.errors.nombre}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Correo electronico */}
      <Grid item xs={12} md={4}>
        <TextField
          id="correo_electronico"
          name="correo_electronico"
          label="Correo electrónico"
          placeholder="Introduce el correo electrónico"
          fullWidth
          margin="normal"
          value={formik.values.correo_electronico}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.correo_electronico &&
            Boolean(formik.errors.correo_electronico)
          }
          helperText={
            formik.touched.correo_electronico &&
            formik.errors.correo_electronico
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Teléfono */}
      <Grid item xs={12} md={3}>
        <Stack direction="row">
          <TextField
            id="telefono"
            name="telefono"
            label="Teléfono"
            placeholder="Introduce el teléfono"
            fullWidth
            margin="normal"
            value={formik.values.telefono}
            onChange={formik.handleChange}
            variant="outlined"
            error={
              formik.touched.telefono && Boolean(formik.errors.telefono)
            }
            helperText={formik.touched.telefono && formik.errors.telefono}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <a
            style={{ paddingTop: 30, marginLeft: 10, color: "#25D366" }}
            href={`https://api.whatsapp.com/send?phone=34${formik.values.telefono}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
          </a>
          <a
            style={{ paddingTop: 30, marginLeft: 10, color: "#072236" }}
            href={`tel:+34${formik.values.telefono}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PhoneIcon />
          </a>
        </Stack>
      </Grid>

      {/* Proximo contacto */}
      <Grid item xs={12} md={2} style={{ marginTop: 16 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDateTimePicker
            fullWidth
            autoOk={true}
            id="proximo_contacto"
            label="Proximo contacto"
            placeholder="Proximo contacto"
            inputVariant="outlined"
            format="dd/MM/yyyy HH:mm:ss"
            value={formik.values.proximo_contacto}
            onChange={(value) =>
              formik.setFieldValue("proximo_contacto", value)
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={
              formik.touched.proximo_contacto &&
              Boolean(formik.errors.proximo_contacto)
            }
            helperText={
              formik.touched.proximo_contacto &&
              formik.errors.proximo_contacto
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* Posible alta */}
      <Grid item xs={2}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.posible_alta_id &&
            Boolean(formik.errors.posible_alta_id)
          }
        >
          <InputLabel id="posible_alta_id-label">Posible alta</InputLabel>
          <Select
            labelId="posible_alta_id-label"
            id="posible_alta_id"
            name="posible_alta_id"
            label="Especialidad"
            fullWidth
            value={formik.values.posible_alta_id}
            onChange={formik.handleChange}
            error={
              formik.touched.posible_alta_id &&
              Boolean(formik.errors.posible_alta_id)
            }
          >
            {posibleAlta &&
              posibleAlta.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.posible_alta_id &&
              formik.errors.posible_alta_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* Temperaturas */}
      <Grid item xs={2}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.temperatura_id &&
            Boolean(formik.errors.temperatura_id)
          }
        >
          <InputLabel id="temperatura_id-label">Temperatura</InputLabel>
          <Select
            labelId="temperatura_id-label"
            id="temperatura_id"
            name="temperatura_id"
            label="Temperatura"
            fullWidth
            value={formik.values.temperatura_id}
            onChange={formik.handleChange}
            error={
              formik.touched.temperatura_id &&
              Boolean(formik.errors.temperatura_id)
            }
          >
            {temperaturas &&
              temperaturas.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.temperatura_id &&
              formik.errors.temperatura_id}
          </FormHelperText>
        </FormControl>
      </Grid>
        
      {/* Como nos ha conocido */}
      <Grid item xs={3}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.como_conocido_id &&
            Boolean(formik.errors.como_conocido_id)
          }
        >
          <InputLabel id="como_conocido_id-label">
            Como nos ha conocido ({Boolean(formik.errors.como_conocido_id) === "true" ? "true" : "false"})
          </InputLabel>
          <Select
            labelId="como_conocido_id-label"
            id="como_conocido_id"
            name="como_conocido_id"
            label="Como nos ha conocido"
            fullWidth
            value={formik.values.como_conocido_id}
            onChange={formik.handleChange}
            error={
              formik.touched.como_conocido_id &&
              Boolean(formik.errors.como_conocido_id)
            }
            readOnly = {Boolean(formik.errors.como_conocido_id)=== "false" ? true : false}
            disabled= {Boolean(formik.errors.como_conocido_id)=== "false" ? true : false}
          >
            {comoConocidos &&
              comoConocidos.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.como_conocido_id &&
              formik.errors.como_conocido_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* Formulario nombre */}
      <Grid item xs={12} md={3}>
        <TextField
          id="formulario_nombre"
          name="formulario_nombre"
          label="Formulario"
          placeholder=""
          fullWidth
          readonly
          margin="normal"
          value={formik.values.formulario_nombre}
          variant="outlined"
          error={
            formik.touched.formulario_nombre &&
            Boolean(formik.errors.formulario_nombre)
          }
          helperText={
            formik.touched.formulario_nombre &&
            formik.errors.formulario_nombre
          }
          InputLabelProps={{
            shrink: true,
          }}
          readOnly
          disabled
        />
      </Grid>

      {/* Situación del alumno */}
      <Grid item xs={3}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.situacion_id &&
            Boolean(formik.errors.situacion_id)
          }
        >
          <InputLabel id="situacion_id-label">
            Situación del alumno
          </InputLabel>
          <Select
            labelId="situacion_id-label"
            id="situacion_id"
            name="situacion_id"
            label="Situación del alumno"
            fullWidth
            value={formik.values.situacion_id}
            onChange={formik.handleChange}
            error={
              formik.touched.situacion_id &&
              Boolean(formik.errors.situacion_id)
            }
          >
            {situaciones &&
              situaciones.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.situacion_id && formik.errors.situacion_id}
          </FormHelperText>
        </FormControl>
      </Grid>
      
      {/* Comercial */}
      <Grid item xs={2}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.comercial_id &&
            Boolean(formik.errors.comercial_id)
          }
        >
          <InputLabel id="comercial_id-label">Comercial</InputLabel>
          <Select
            labelId="comercial_id-label"
            id="comercial_id"
            name="comercial_id"
            label="Comercial"
            fullWidth
            // readOnly = { usuarioSeleccionado.perfil_id !== "1" ? true : false}
            value={formik.values.comercial_id}
            onChange={formik.handleChange}
            error={
              formik.touched.comercial_id &&
              Boolean(formik.errors.comercial_id)
            }
          >
            {comerciales &&
              comerciales.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.comercial_id && formik.errors.comercial_id}
          </FormHelperText>
        </FormControl>
      </Grid>
      
      {/* Comunidades autonomas */}
      <Grid item xs={2}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.comunidad_autonoma_id &&
            Boolean(formik.errors.comunidad_autonoma_id)
          }
        >
          <InputLabel id="comunidad_autonoma-label">
            Comunidad autónoma
          </InputLabel>
          <Select
            labelId="comunidad_autonoma_id-label"
            id="comunidad_autonoma_id"
            name="comunidad_autonoma_id"
            label="Comunidad autonoma"
            fullWidth
            value={formik.values.comunidad_autonoma_id}
            onChange={formik.handleChange}
            error={
              formik.touched.comunidad_autonoma_id &&
              Boolean(formik.errors.comunidad_autonoma_id)
            }
          >
            {comunidadesAutonomas &&
              comunidadesAutonomas.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.comunidad_autonoma_id &&
              formik.errors.comunidad_autonoma_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* Oposicion */}
      <Grid item xs={2}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.oposicion_id &&
            Boolean(formik.errors.oposicion_id)
          }
        >
          <InputLabel id="oposicion_id-label">Oposicion</InputLabel>
          <Select
            labelId="oposicion_id-label"
            id="oposicion_id"
            name="oposicion_id"
            label="Oposicion"
            fullWidth
            value={formik.values.oposicion_id}
            onChange={formik.handleChange}
            error={
              formik.touched.oposicion_id &&
              Boolean(formik.errors.oposicion_id)
            }
          >
            {oposiciones &&
              oposiciones.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.oposicion_id && formik.errors.oposicion_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* Especialidad */}
      <Grid item xs={3}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.especialidad_id &&
            Boolean(formik.errors.especialidad_id)
          }
        >
          <InputLabel id="especialidad_id-label">Especialidad</InputLabel>
          <Select
            labelId="especialidad_id-label"
            id="especialidad_id"
            name="especialidad_id"
            label="Especialidad"
            fullWidth
            value={formik.values.especialidad_id}
            onChange={formik.handleChange}
            error={
              formik.touched.especialidad_id &&
              Boolean(formik.errors.especialidad_id)
            }
          >
            {especialidades &&
              especialidades.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.especialidad_id &&
              formik.errors.especialidad_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* Observaciones */}
      <Grid item xs={12}>
        <TextField
          id="formulario_observaciones"
          name="formulario_observaciones"
          label="Consulta"
          placeholder="Consulta del formulario"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={formik.values.formulario_observaciones}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.formulario_observaciones &&
            Boolean(formik.errors.formulario_observaciones)
          }
          helperText={
            formik.touched.formulario_observaciones &&
            formik.errors.formulario_observaciones
          }
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="mensaje_formulario"
          name="mensaje_formulario"
          label=""
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={formik.values.mensaje_formulario}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.mensaje_formulario &&
            Boolean(formik.errors.mensaje_formulario)
          }
          helperText={
            formik.touched.mensaje_formulario &&
            formik.errors.mensaje_formulario
          }
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      {/* Motivo perdida */}
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{  }}
          error={
            formik.touched.motivo_perdida_id &&
            Boolean(formik.errors.motivo_perdida_id)
          }
        >
          <InputLabel id="motivo_perdida_id-label">
            Motivo pérdida
          </InputLabel>
          <Select
            labelId="motivo_perdida_id-label"
            id="motivo_perdida_id"
            name="motivo_perdida_id"
            label="Motivo pérdida"
            fullWidth
            value={formik.values.motivo_perdida_id}
            onChange={formik.handleChange}
            error={
              formik.touched.motivo_perdida_id &&
              Boolean(formik.errors.motivo_perdida_id)
            }
          >
            {motivosPerdida &&
              motivosPerdida.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.motivo_perdida_id &&
              formik.errors.motivo_perdida_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* Demo */}
      <Grid item xs={12} md={12}>
        <Box style={{ marginBottom: 16}}>
          {
            alumno?.demo_enviada === "1" &&
            <>
              <b>Ultima demo enviada: </b>{alumno?.demo_fecha} - {alumno?.demo_usuario_nombre}
            </>
          }
        </Box>
        <Button variant="contained" color="primary" onClick={ () => {
          enviar_demo({usuario_id: usuarioSeleccionado.id, alumno_id: alumno.id}).then((response) => {
            Swal.fire("Demo enviada", "", "info");

            setAlumno(response.alumno)
        })} }
        >
          Enviar demo
        </Button>
      </Grid>
    </Grid>
  );
};

/* -------------------------------- Separator ------------------------------- */
const Separator = ({ children }) => {
  // const color = "#D0CCD0";
  const color = "#9FB1BC";
  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <hr style={{flexGrow: 1, display: "block", height: 1, border: 0, borderTop: `1px solid ${color}`, margin: "1em 0", padding: 0}} />
        <span style={{padding: "0 10px 0 10px", color: `${color}`, textTransform: "uppercase"}}>{children}</span>
      <hr style={{flexGrow: 1, display: "block", height: 1, border: 0, borderTop: `1px solid ${color}`, margin: "1em 0", padding: 0}} />
    </Box>
  );
};

/* -------------------------- FormDatosFacturacion -------------------------- */
const FormDatosFacturacion = ({ formik }) => {
  const [motivosBaja, setMotivosBaja] = useState([]);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  useEffect(() => {
    const usuario_id = usuarioSeleccionado.id;

    // Motivos baja
    getListaDetalle({ usuario_id: usuario_id, lista_id: 15 }).then(
      (response) => {
        response.lista_detalle && setMotivosBaja(response.lista_detalle);
      }
    );
    
  }, []);

  return (
    <Grid container spacing={2}>

      {/* ----------------------------- Separador alta ----------------------------- */}
      <Grid item xs={12} md={12}>
        <Separator>Datos de alta</Separator>
      </Grid>

      {/* Fecha de alta */}
      <Grid item xs={6} md={3} style={{ }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            fullWidth
            id="fecha_alta"
            label="Fecha Alta"
            placeholder="Introduce la fecha de alta"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={formik.values.fecha_alta}
            onChange={(value) =>
              formik.setFieldValue("fecha_alta", value)
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={
              formik.touched.fecha_alta &&
              Boolean(formik.errors.fecha_alta)
            }
            helperText={
              formik.touched.fecha_alta && formik.errors.fecha_alta
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* Alumno desde */}
      <Grid item xs={6} md={3} style={{ }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            fullWidth
            id="alumno_desde"
            label="Alumno desde"
            placeholder="Alumno desde"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={formik.values.alumno_desde}
            onChange={(value) =>
              formik.setFieldValue("alumno_desde", value)
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={
              formik.touched.alumno_desde &&
              Boolean(formik.errors.alumno_desde)
            }
            helperText={
              formik.touched.alumno_desde && formik.errors.alumno_desde
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>
      
      {/* Importe a cuenta */}
      <Grid item xs={2}>
        <TextField
          style={{ marginTop: 0 }}
          id="importe_a_cuenta"
          name="importe_a_cuenta"
          label="Importe a cuenta"
          placeholder="Introduce el importe"
          fullWidth
          margin="normal"
          value={formik.values.importe_a_cuenta}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.importe_a_cuenta &&
            Boolean(formik.errors.importe_a_cuenta)
          }
          helperText={
            formik.touched.importe_a_cuenta &&
            formik.errors.importe_a_cuenta
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* A cuenta toda preparación */}
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                formik.values.a_cuenta_toda_preparacion === "1" ||
                formik.values.a_cuenta_toda_preparacion === true
                  ? true
                  : false
              }
              onChange={formik.handleChange}
              name="a_cuenta_toda_preparacion"
            />
          }
          label="A cuenta toda preparación"
        />
      </Grid>
      
      {/* ---------------------------- Separador Alumno ---------------------------- */}
      <Grid item xs={12} md={12}>
        <Separator>Datos del alumno</Separator>
      </Grid>

      {/* DNI */}
      <Grid item xs={12} md={2}>
        <TextField
          style={{ marginTop: 0 }}
          id="dni"
          name="dni"
          label="DNI/CIF"
          placeholder="Introduce el DNI"
          fullWidth
          margin="normal"
          value={formik.values.dni}
          onChange={formik.handleChange}
          variant="outlined"
          error={formik.touched.dni && Boolean(formik.errors.dni)}
          helperText={formik.touched.dni && formik.errors.dni}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Dirección */}
      <Grid item xs={12} md={7}>
        <TextField
          style={{ marginTop: 0 }}
          id="direccion"
          name="direccion"
          label="Direccion"
          placeholder="Introduce la direccion"
          fullWidth
          margin="normal"
          value={formik.values.direccion}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.direccion && Boolean(formik.errors.direccion)
          }
          helperText={formik.touched.direccion && formik.errors.direccion}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Código Postal */}
      <Grid item xs={12} md={3}>
        <TextField
          style={{ marginTop: 0 }}
          id="codigo_postal"
          name="codigo_postal"
          label="C.P"
          placeholder="Introduce Código Postal"
          fullWidth
          margin="normal"
          value={formik.values.codigo_postal}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.codigo_postal &&
            Boolean(formik.errors.codigo_postal)
          }
          helperText={
            formik.touched.codigo_postal && formik.errors.codigo_postal
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Ciudad */}
      <Grid item xs={12} md={3}>
        <TextField
          style={{ marginTop: 0 }}
          id="ciudad"
          name="ciudad"
          label="ciudad"
          placeholder="Introduce la ciudad"
          fullWidth
          margin="normal"
          value={formik.values.ciudad}
          onChange={formik.handleChange}
          variant="outlined"
          error={formik.touched.ciudad && Boolean(formik.errors.ciudad)}
          helperText={formik.touched.ciudad && formik.errors.ciudad}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Provincia */}
      <Grid item xs={12} md={3}>
        <TextField
          style={{ marginTop: 0 }}
          id="provincia"
          name="provincia"
          label="Provincia"
          placeholder="Introduce la provincia"
          fullWidth
          margin="normal"
          value={formik.values.provincia}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.provincia && Boolean(formik.errors.provincia)
          }
          helperText={formik.touched.provincia && formik.errors.provincia}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Pais */}
      <Grid item xs={12} md={3}>
        <TextField
          style={{ marginTop: 0 }}
          id="pais"
          name="pais"
          label="Pais"
          placeholder="Introduce el pais"
          fullWidth
          margin="normal"
          value={formik.values.pais}
          onChange={formik.handleChange}
          variant="outlined"
          error={formik.touched.pais && Boolean(formik.errors.pais)}
          helperText={formik.touched.pais && formik.errors.pais}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* ----------------------------- Separador baja ----------------------------- */}
      <Grid item xs={12} md={12}>
          <Separator>Datos de baja</Separator>
      </Grid>

      {/* Fecha de baja */}
      <Grid item xs={6} md={3} style={{  }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            fullWidth
            id="fecha_baja"
            label="Fecha baja"
            placeholder="Fecha baja"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={formik.values.fecha_baja}
            onChange={(value) =>
              formik.setFieldValue("fecha_baja", value)
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={
              formik.touched.fecha_baja &&
              Boolean(formik.errors.fecha_baja)
            }
            helperText={
              formik.touched.fecha_baja && formik.errors.fecha_baja
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* Fecha posible baja */}
      <Grid item xs={6} md={3} style={{  }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            fullWidth
            id="fecha_posible_baja"
            label="Fecha posible baja"
            placeholder="Fecha posible baja"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={formik.values.fecha_posible_baja}
            onChange={(value) =>
              formik.setFieldValue("fecha_posible_baja", value)
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={
              formik.touched.fecha_posible_baja &&
              Boolean(formik.errors.fecha_posible_baja)
            }
            helperText={
              formik.touched.fecha_posible_baja &&
              formik.errors.fecha_posible_baja
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* Motivo baja */}
      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ }}
          error={
            formik.touched.motivo_baja_id &&
            Boolean(formik.errors.motivo_baja_id)
          }
        >
          <InputLabel id="motivo_baja_id-label">Motivo baja</InputLabel>
          <Select
            labelId="motivo_baja_id-label"
            id="motivo_baja_id"
            name="motivo_baja_id"
            label="Motivo baja"
            fullWidth
            value={formik.values.motivo_baja_id}
            onChange={formik.handleChange}
            error={
              formik.touched.motivo_baja_id &&
              Boolean(formik.errors.motivo_baja_id)
            }
          >
            <MenuItem key={0} value={0}>
              Sin definir
            </MenuItem>
            {motivosBaja &&
              motivosBaja.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.motivo_baja_id &&
              formik.errors.motivo_baja_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* Motivo baja */}
      <Grid item xs={12}>
        <TextField
          style={{ marginTop: 0 }}
          id="motivo_baja"
          name="motivo_baja"
          label="Explicación Motivo baja"
          placeholder="Introduce el motivo de la baja"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={formik.values.motivo_baja}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.motivo_baja &&
            Boolean(formik.errors.motivo_baja)
          }
          helperText={
            formik.touched.motivo_baja && formik.errors.motivo_baja
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Baja tutea */}
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                formik.values.es_baja_tutea === "1" ||
                formik.values.es_baja_tutea === true
                  ? true
                  : false
              }
              onChange={formik.handleChange}
              name="es_baja_tutea"
            />
          }
          label="Baja tutea"
        />
      </Grid>

      {/* Baja portal alumno */}
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                formik.values.es_baja_portal_alumno === "1" ||
                formik.values.es_baja_portal_alumno === true
                  ? true
                  : false
              }
              onChange={formik.handleChange}
              name="es_baja_portal_alumno"
            />
          }
          label="Baja portal alumno"
        />
      </Grid>

    </Grid>
  );
};

/* ----------------------------- FormDatosAlumno ---------------------------- */
const FormDatosAlumno = ({ formik, alumno }) => {
  const [tutores, setTutores] = useState([]);
  const [listaDedicacion, setListaDedicacion] = useState([]);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  useEffect(() => {
    const usuario_id = usuarioSeleccionado.id;

    // Tutores
    getTutores({ usuario_id: usuario_id }).then((response) => {
      response.tutores && setTutores(response.tutores);
    });

    // Lista dedicacion
    getListaDetalle({ usuario_id: usuario_id, lista_id: 19 }).then(
      (response) => {
        response.lista_detalle && setListaDedicacion(response.lista_detalle);
      }
    );
  }
  , []);

  return (
    <Grid container spacing={2}>

      {/* ------------------------- Separador preferencias ------------------------- */}
      <Grid item xs={12} md={12}>
        <Separator>Preferencias del alumno</Separator>
      </Grid>

      {/* Tutor preferido */}
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.tutor_preferido_id &&
            Boolean(formik.errors.tutor_preferido_id)
          }
        >
          <InputLabel id="tutor_preferido_id-label">
            Tutor preferido
          </InputLabel>
          <Select
            labelId="tutor_preferido_id-label"
            id="tutor_preferido_id"
            name="tutor_preferido_id"
            label="Tutor Preferido"
            fullWidth
            value={formik.values.tutor_preferido_id}
            onChange={formik.handleChange}
            error={
              formik.touched.tutor_preferido_id &&
              Boolean(formik.errors.tutor_preferido_id)
            }
          >
            <MenuItem key={0} value={0}>
              Sin definir
            </MenuItem>
            {tutores &&
              tutores.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.tutor_preferido_id &&
              formik.errors.tutor_preferido_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* ------------------------- Separador estadisticas ------------------------- */}
      <Grid item xs={12} md={12}>
        <Separator>Datos estadísticos</Separator>
      </Grid>

      {/* Numero de convocatorias */}
      <Grid item xs={2}>
        <TextField
          id="num_convocatorias"
          name="num_convocatorias"
          label="Num. convocatorias"
          placeholder="Introduce el numero de convocatorias"
          fullWidth
          margin="normal"
          value={formik.values.num_convocatorias}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.num_convocatorias &&
            Boolean(formik.errors.num_convocatorias)
          }
          helperText={
            formik.touched.num_convocatorias &&
            formik.errors.num_convocatorias
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Ultima convocatoria */}
      <Grid item xs={2}>
        <TextField
          id="ultima_convocatoria_year"
          name="ultima_convocatoria_year"
          label="Año ultima convocatoria"
          placeholder="Año"
          fullWidth
          margin="normal"
          value={formik.values.ultima_convocatoria_year}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.ultima_convocatoria_year &&
            Boolean(formik.errors.ultima_convocatoria_year)
          }
          helperText={
            formik.touched.ultima_convocatoria_year &&
            formik.errors.ultima_convocatoria_year
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Fecha nacimiento */}
      <Grid item xs={6} md={2} style={{ marginTop: 16 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            fullWidth
            id="fecha_nacimiento"
            label="Fecha nacimiento"
            placeholder="Fecha nacimiento"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={formik.values.fecha_nacimiento}
            onChange={(value) =>
              formik.setFieldValue("fecha_nacimiento", value)
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={
              formik.touched.fecha_nacimiento &&
              Boolean(formik.errors.fecha_nacimiento)
            }
            helperText={
              formik.touched.fecha_nacimiento &&
              formik.errors.fecha_nacimiento
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* Horas de estudio */}
      <Grid item xs={2}>
        <TextField
          id="horas_estudio"
          name="horas_estudio"
          label="Horas estudio (dia)"
          placeholder="Año"
          fullWidth
          margin="normal"
          value={formik.values.horas_estudio}
          onChange={formik.handleChange}
          variant="outlined"
          error={
            formik.touched.horas_estudio &&
            Boolean(formik.errors.horas_estudio)
          }
          helperText={
            formik.touched.horas_estudio && formik.errors.horas_estudio
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Dedicacion */}
      <Grid item xs={4}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          error={
            formik.touched.dedicacion_id &&
            Boolean(formik.errors.dedicacion_id)
          }
        >
          <InputLabel id="dedicacion_id-label">Dedicación</InputLabel>
          <Select
            labelId="dedicacion_id-label"
            id="dedicacion_id"
            name="dedicacion_id"
            label="Dedicación"
            fullWidth
            value={formik.values.dedicacion_id}
            onChange={formik.handleChange}
            error={
              formik.touched.dedicacion_id &&
              Boolean(formik.errors.dedicacion_id)
            }
          >
            {listaDedicacion &&
              listaDedicacion.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText>
            {formik.touched.dedicacion_id && formik.errors.dedicacion_id}
          </FormHelperText>
        </FormControl>
      </Grid>

      {/* ------------------------- Separador asignaciones ------------------------- */}
      <Grid item xs={12} md={12}>
        <Separator>Asignaciones del alumno</Separator>
      </Grid>

      {/* Datos tutor y tutoria */}
      <Grid item xs={12} md={12} style={{marginBottom: 20}}>
        <Typography variant="body1">
          <b>Tutoría asignada: </b>
          {alumno && alumno.tutor_nombre}{" "}
        </Typography>
        <Typography variant="body1">
          <b>Grupo asignado: </b>
          {alumno && alumno.grupo_descripcion}{" "}
        </Typography>
      </Grid>
            
    </Grid>
  );
};

/* ---------------------------- ContainerSeccion ---------------------------- */
const ContainerSeccion = ({ titulo, children }) => {
  return (
    <Grid container spacing={2} style={{ marginBottom: 20 }}>
      <Grid item xs={12} md={12}>
        <Paper elevation={0} style={{ padding: 10, border: "1px solid #ccc" }}>
          <Typography variant="h6">{titulo}</Typography>
          <Divider style={{marginBottom: 20}} />
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
}

/* -------------------------- HeaderAlumnoPrincipal ------------------------- */
const HeaderAlumnoPrincipal = (alumno, alumnoPrincipalRecomendado, usuarioSeleccionado, setAlumno) => {
  let history = useHistory();

  return (
    <>
      {alumno && alumno.alumno_principal_id === null && (
              <Alert
                severity="error"
                action={
                  <>
                    <Button
                      color="inherit"
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        updateAlumnoPrincipalId({
                          usuario_id: usuarioSeleccionado.id,
                          alumno_id: alumno.id,
                          alumno_principal_id: alumno.id,
                        }).then((response) => {
                          Swal.fire("Ficha principal definida", "", "info");
                          setAlumno({
                            ...alumno,
                            alumno_principal_id: alumno.id,
                          });
                        });
                      }}
                    >
                      Definir esta ficha como principal
                    </Button>
                  </>
                }
              >
                Este alumno no tiene ficha principal
              </Alert>
            )}
            {alumno && alumno.alumno_principal_id === null && alumnoPrincipalRecomendado && (
              <Alert
                severity="info"
                style={{marginTop: 10}}
                action={
                  <>
                    <Button
                      color="inherit"
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        updateAlumnoPrincipalId({
                          usuario_id: usuarioSeleccionado.id,
                          alumno_id: alumno.id,
                          alumno_principal_id: alumnoPrincipalRecomendado.id,
                        }).then((response) => {
                            // history.push(`/alumnos/detalle/${alumno.id}`)
                            history.go(0)
                          Swal.fire("Ficha principal definida", "", "info");
                          setAlumno({
                            ...alumno,
                            alumno_principal_id: alumnoPrincipalRecomendado.id,
                          });

                        });
                      }}
                    >
                      Definir la ficha recomendada [{alumnoPrincipalRecomendado.id}] como principal
                    </Button>
                  </>
                }
              >
                Ficha recomendada: [{ alumnoPrincipalRecomendado.id }] {alumnoPrincipalRecomendado.nombre}
              </Alert>
            )}
            {alumno &&
              alumno.alumno_principal_id !== null &&
              alumno.alumno_principal_id === alumno.id && (
                <Alert severity="info">Esta es la ficha principal</Alert>
              )}
            {alumno &&
              alumno.alumno_principal_id !== null &&
              alumno.alumno_principal_id !== alumno.id && (
                <Alert severity="info">
                  Ir a la ficha principal de este alumno
                  <Link
                    href={`/alumnos/detalle/${alumno.alumno_principal_id}`}
                    style={{ marginLeft: 10 }}
                  >
                    ABRIR
                  </Link>
                </Alert>
              )}
    </>
  )
}

/* -------------------------------- TabPanel -------------------------------- */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/* -------------------------------- a11yProps ------------------------------- */
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
//#endregion
export default AlumnosForm;
